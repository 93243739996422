export function primeConfig () {
    return {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ["Chủ nhật", "Thứ hai", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
        dayNamesShort: ["CN", "T2", "T3", "T4", "T4", "T6", "T7"],
        dayNamesMin: ["CN", "T2", "T3", "T4", "T4", "T6", "T7"],
        monthNames: ["Tháng 1","Tháng 2","Tháng 3","Tháng 4","Tháng 5","Tháng 6","Tháng 7","Tháng 8","Tháng 9","Tháng 10","Tháng 11","Tháng 12"],
        monthNamesShort: ["Tháng 1","Tháng 2","Tháng 3","Tháng 4","Tháng 5","Tháng 6","Tháng 7","Tháng 8","Tháng 9","Tháng 10","Tháng 11","Tháng 12"],
        today: 'Hôm nay',
        weekHeader: 'Tuần',
        firstDayOfWeek: 0,
        dateFormat: "dd'/'mm'/'yy",
        weak: 'Yếu',
        medium: 'Trung bình',
        strong: 'Mạnh',
        passwordPrompt: 'Nhập mật khẩu',
        emptyFilterMessage: 'Không có kết quả',
        emptyMessage: 'Không có mục nào'
    }
}
